<template>
  <div class="filter">
    <button class="btn btnStyle btn-success" @click="$emit('export')">
      <FontAwesomeIcon icon="fa-light fa-file-pdf" />
      {{ $t("studea_manager.dashboard.export.title") }}
    </button>
    <button
      class="d-block btn mt-2 btn-light text-success"
      @click="$emit('filter')"
    >
      <FontAwesomeIcon icon="fa-light fa-filter" />
      {{ $t("studea_manager.dashboard.filter") }}
    </button>
    <button
      class="d-block btn mt-2 btn-light text-success"
      @click="$emit('reset')"
      v-if="isFiltered"
    >
      <FontAwesomeIcon icon="fa-light fa-xmark" />
      {{ $t("studea_manager.dashboard.delete_filters") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "FilterBlock",
  props: {
    isFiltered: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.filter {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 20px;
  left: 20px;

  button {
    width: 250px;
    margin-bottom: 10px;
  }

  svg {
    margin-right: 8px;

    @include dark-theme {
      &.fa-filter {
        color: $green !important;
      }
    }
  }
}
</style>
